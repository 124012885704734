import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link, useStaticQuery } from 'gatsby';

const ProjectsFilter = ({resetProjects, filterProjects, preset}) => {

   const projectSectors = useStaticQuery(graphql`
        query projectSectors {
            projectSectors: allContentfulProjectSectors(sort: {order: ASC, fields: name}) {
                edges {
                    node {
                        name
                    }
                }
            }
        }
    `)

    const filterSearch = (e) => {
        resetProjects();
        if (e.target.value !== '') {
            filterProjects(prev => prev.filter(({node}) => node.name.toLowerCase().includes((e.target.value).toLowerCase())))
        } else {
            resetProjects();
        }
    }

    const filterLocation = (e) => {
        resetProjects();
        if (e.target.value !== '') {
            //prefix with space to avoid matching partial words
            filterProjects(prev => prev.filter(({node}) => node.location.toLowerCase().includes((' ' + e.target.value).toLowerCase())))
        } else {
            resetProjects();
        }
    }

    const filterSector = (e) => {
        resetProjects();
        if (e.target.value !== '') {
            filterProjects(prev => prev.filter(({node}) => {
                return node.projectSectors && node.projectSectors.find( ({name}) => name === e.target.value)
            }
            ))
        } else {
            resetProjects();
        }
    }

    const filterStatus = (e) => {
        resetProjects();
        if (e.target.value !== '') {
            filterProjects(prev => prev.filter(({node}) => node.status?.toLowerCase().includes((e.target.value).toLowerCase()) ?? false))
        } else {
            resetProjects();
        }
    }

    return (

        <div className="container mx-auto">

            <div className="sm:flex projects-filter">

                <div className="sm:w-1/4 p-4">
                    <label className="text-white text-lg">Search
                        <input className="mt-2 border soft-gray px-4 py-2 w-full focus:outline-none focus:border-mane-yellow"
                            placeholder="Enter keyword"
                            type="search"
                            onChange={(e)=>filterSearch(e)}
                        /> 
                    </label>
                </div>

                <div className="sm:w-1/4 p-4">
                    <label className="text-white text-lg">Country/Region</label>
                       <select className="mt-2 w-full px-4 py-2 bg-gray-100 border focus:outline-none focus:border-mane-yellow"
                            onChange={(e)=>filterLocation(e)}
                       >
                           <option value="">All</option>
                            <option value="cyprus">Cyprus</option>
                            <option value="ghana">Ghana</option>
                            <option value="hungary">Hungary</option>
                            <option value="iraq">Iraq</option>
                            <option value="jordan">Jordan</option>
                            <option value="saudi">KSA</option>
                            <option value="lebanon">Lebanon</option>
                            <option value="libya">Libya</option>
                            <option value="mali">Mali</option>
                            <option value="qatar">Qatar</option>
                            <option value="sudan">Sudan</option>
                            <option value="uae">UAE</option>
                       </select>
                </div>

                <div className="sm:w-1/4 p-4">
                    <label className="text-white text-lg">Sector</label>
                       <select className="mt-2 w-full px-4 py-2 bg-gray-100 border focus:outline-none focus:border-mane-yellow"
                            onChange={(e)=>filterSector(e)}
                       >
                           <option value="">All</option>
                           {projectSectors.projectSectors.edges.map(({node}) => (
                               <option value={node.name}>{node.name}</option>
                               )
                           )}
                      </select>
                </div>

                <div className="sm:w-1/4 p-4">
                    <label className="text-white text-lg">Status</label>
                          <select className="mt-2 w-full px-4 py-2 bg-gray-100 border focus:outline-none focus:border-mane-yellow"
                                onChange={(e)=>filterStatus(e)}
                           >
                            <option value="">All</option>
                            <option value="executed">Executed</option>
                            <option value="on-going">On-Going</option>
                       </select>
                </div>



            </div>

        </div>

    )
}

export default ProjectsFilter;
